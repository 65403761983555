<template>
  <div id="app" class="body" @contextmenu.prevent>
    <router-view  />
  </div>
</template>
<style>
.body{
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>

<script>
export default {
  name: 'App',
  data() {
    return {
      isRouterShow: true
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
  }
}
</script>
