import Vue from 'vue'
import App from './App'

import vant from 'vant-green'
import 'vant-green/lib/index.css';

// 菜单和路由设置
import router from '@/router/routes'

import './router/index' // permission control

Vue.config.productionTip = false
Vue.use(vant)

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
