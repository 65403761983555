import router from './routes'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style

NProgress.configure({ showSpinner: false })// NProgress Configuration


router.beforeEach(async(to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  NProgress.start()
  next()
  NProgress.done()
   
})
// 设置浏览器缩放为100%
// function setZoomTo100() {
//   const scale = window.screen.width / window.screen.availWidth;
//   const zoom = `scale(${scale})`;
//   document.body.style.transform = zoom;
//   document.body.style.transformOrigin = '0 0';
//   document.body.style.width = `${window.screen.width}px`;
//   document.body.style.height = `${window.screen.height}px`;
// }
router.afterEach(() => {
  //每次路由完成，界面缩放修改为100%
  // setZoomTo100()
    // 进度条
  NProgress.done()
})
