<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  mounted() {
    console.log("key", this.key);
  },
};
</script>
