import Vue from 'vue'
import Router from 'vue-router'
// fix vue-router NavigationDuplicated
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

Vue.use(Router)// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

/* Layout */
import Layout from '@/layout'

/**
 * 在主框架内显示
 */
export const constantRouterMap = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path*',
      component: () => import('@/views/redirect/index.vue')
    }]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      component: () => import('@/views/home/index.vue'),
      name: 'home',
      meta: {
        title: '首页',
        icon: 'home',
        affix: true
      }
    }]
  },
  {
    path: '/user',
    component: Layout,
    redirect: '/user/register',
    children: [
      {
        path: 'register',
        component: () => import('@/views/user-register/index.vue'),
        name: 'register',
        meta: {
          title: '会议报名',
          icon: 'home',
          affix: true
        }
      },
      {
        path: 'sign',
        component: () => import('@/views/user-sign/index.vue'),
        name: 'sign',
        meta: {
          title: '在线签到',
          icon: 'home',
          affix: true
        }
      },
      {
        path: 'shouce',
        component: () => import('@/views/shouce/index.vue'),
        name: 'shouce',
        meta: {
          title: '会议手册',
          icon: 'home',
          affix: true
        }
      },
      {
        path: 'liucheng',
        component: () => import('@/views/liucheng/index.vue'),
        name: 'liucheng',
        meta: {
          title: '会议议程',
          icon: 'home',
          affix: true
        }
      },
      {
        path: 'wenxintishi',
        component: () => import('@/views/weixintishi/index.vue'),
        name: 'wenxintishi',
        meta: {
          title: '温馨提示',
          icon: 'home',
          affix: true
        }
      },
      {
        path: 'zuoweitu',
        component: () => import('@/views/zuoweitu/index.vue'),
        name: 'zuoweitu',
        meta: {
          title: '座位图',
          icon: 'home',
          affix: true
        }
      }
    ]
  }
]

const createRouter = () =>
  new Router({
    mode: 'hash',
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
  })

const router = createRouter()

// 定义一个resetRouter 方法，在退出登录时，调用即可
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
